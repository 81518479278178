<!-- Menu header -->
<div class="navbar-header" style="height: auto;">
  <ul class="nav navbar-nav ">
    <li class="nav-item d-flex justify-content-center">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/evaluadores']">
        <span class="brand-logo">
          <img style="width: 190px;" src="../../../../../assets/images/logo/rbmblanco.PNG" alt="brand-logo"/>
        </span>
        <!-- <img class="brand-text" src="assets/images/logo/pagosRecurrentes.png" alt="brand-logo" height="50" />    -->
      </a>
      
      <!--
      <a class="navbar-brand" [routerLink]="['/']">
        <span>
          <div class="brand-logo">
            <img class ="brand-text2" src="{{ coreConfig.app.appRosetaImage }}" alt="brand-logo" />
          </div>
          <img class = "brand-text3" src="{{ coreConfig.app.appLogoImage }}" height="55"/>
        </span>
      </a>
      -->
    </li>
    
    <li class="nav-item d-flex justify-content-center">
      <p style="text-align: center; font-weight: bold; color: white; ">
        EVALUACIÓN DE<br> DESEMPEÑO<br>
      </p>
    </li>
    <!-- Menu Toggler -->
    <!-- <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
  </ul>
</div>
<!--/ Menu header -->
<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()" >
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
  
      <!-- <ul class="nav navbar-nav flex-row mt-4">
        <li class="nav-item mr-auto">
          <a class="navbar-brand" [routerLink]="['/carga']">
            <span>
              <img class = "brand-text3" style="position: absolute;bottom: 100px;" src="{{ coreConfig.app.appLogoImage }}" height="70"/>
            </span>
          </a>
      </ul> -->
</div>
<!--/ Main menu -->


