<body>
  <div >  
    <div class="row">
      <div class="col-sm-4">
        <div class="card" style="background-color: rgb(219, 69, 69);">
          <div class="{{leftView}}"> <!--agregar la variable de cambio-->
            <img class="imgAvatar" src="{{urlImage}}{{imgID_evaluado}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
            width="42" height="42" data-placement="left"/>    
            <h5 class="nombreTitulo" >{{evaluacion.nombreEvaluado}}</h5>
            <p class="tipoUsuario">Evaluado</p>
            <h6 class="nombreCompetencia">{{nombreCompetenciainitial}}</h6>
            <p class="descripcion" > {{descripcion}}</p>
          </div>
        </div>
      </div>


      <div class="col-sm-8">
        <div class="card">
          <div class="card-bodyright">
            <div class="card-header">

              <h5 class="tituloEvaluacion" >Evaluación Desempeño</h5>
              <img style="width: 20%; " src="https://i.ibb.co/YtCwx82/asdasd.png" alt=""><br>

            </div>
            
            
            <div class="spinner-border text-primary" style="margin-bottom: 150px; margin-top: 150px;" role="status" *ngIf="cargandoPregunta">
              <span class="sr-only"  >Loading...</span>
            </div>         
            <form #myForm="ngForm" (submit)="templateForm(myForm.value)"  class="formPregunta" *ngIf="!cargandoPregunta" >
              <div *ngFor="let dato of datos">
                <p class="nombrePregunta" >{{dato.preguntaCompetencia}}</p>

              <div class="container custom-control custom-radio custom-control-primary" *ngIf="dato.respuestaA">
                <input type="radio" value="A" id="customRadio1{{dato.idPreguntas}}" name={{dato.idPreguntas}} class="custom-control-input" ngModel  />
                <label class="custom-control-label" for="customRadio1{{dato.idPreguntas}}">{{dato.respuestaA}}</label>               
              </div>

              <div class="container custom-control custom-control-primary custom-radio" *ngIf="dato.respuestaB">
                <input type="radio" value="B" id="customRadio2{{dato.idPreguntas}}" name={{dato.idPreguntas}} class="custom-control-input" ngModel />
                <label class="custom-control-label" for="customRadio2{{dato.idPreguntas}}">{{dato.respuestaB}}</label>
              </div>

              <div class="custom-control custom-control-primary custom-radio" *ngIf="dato.respuestaC">
                <input type="radio" value="C" id="customRadio3{{dato.idPreguntas}}" name={{dato.idPreguntas}} class="custom-control-input " ngModel />
                <label class="custom-control-label" for="customRadio3{{dato.idPreguntas}}">{{dato.respuestaC}}</label>
              </div>

              <div class="custom-control custom-control-primary custom-radio" *ngIf="dato.respuestaD">

                <input type="radio" value="D" id="customRadio4{{dato.idPreguntas}}" name={{dato.idPreguntas}} class="custom-control-input " ngModel />
                <label class="custom-control-label" for="customRadio4{{dato.idPreguntas}}">{{dato.respuestaD}}</label>
              </div>

              <div class="custom-control custom-control-primary custom-radio" *ngIf="dato.respuestaE">

                <input type="radio" value="E" id="customRadio5{{dato.idPreguntas}}" name={{dato.idPreguntas}} class="custom-control-input " ngModel />
                <label class="custom-control-label" for="customRadio5{{dato.idPreguntas}}">{{dato.respuestaE}}</label>
              </div>
              </div>

              

              <div class="botones" >  
                <!--<button class="btn btn-danger" style="margin-right: 10px;" *ngIf="devolver" (click)="devolverPregunta()">Volver</button> -->
                <button class="btn btn-warning" type="submit"  (click)="enviarRespuesta()">Guardar y continuar</button>
              </div>

            </form> 
          
          </div>
        </div>
      </div>
    </div>
  </div>

</body>