<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="card" *ngIf="!showMotivo && !showAgregar">
    <div class="modal-content p-0">
        <div class="card-body">
            <form class="form form-horizontal" [formGroup]="formBuscar">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="searchValue" formControlName="nombre"
                                placeholder="Nombre del colaborador"  (keyup)="filterAll()"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-primary" (click)="buscarSubalterno()">
                                    <i data-feather="search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="card card-employee-task">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-header">
                                    <h4 class="card-title">Colaboradores</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="col-md-3 col-md-12 d-flex d-flex justify-content-end">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <div class="d-flex align-items-center">
                                            <label class="d-flex align-items-center">Ver&nbsp;
                                                <select class="form-control mx-20" [(ngModel)]="selectedOption"
                                                    (ngModelChange)="changePageSize($event)">
                                                    <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">
                                                        {{opt.key}}</option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-datatable #table [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap"
                            [limit]="selectedOption" [columnMode]="ColumnMode.force" [headerHeight]="120"
                            [footerHeight]="50" [scrollbarH]="true" [messages]="{emptyMessage: ''}">
                            <ngx-datatable-column name="Nombre" prop="nombre" [width]="250">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="d-flex">
                                        <div class="avatar mr-75">
                                            <img src="{{urlImage}}{{row.ID_colaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                class="" width="42" height="42" data-placement="left"/>
                                        </div>
                                        <div class="columnNombre">
                                            {{row?.nombre}}
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Ver" [width]="50">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <button type="button" (click)="verEvaluadores(row)"
                                        class="btn btn-icon rounded-circle btn-outline-primary"
                                        title="Evaluadores de {{row?.nombre}}">
                                        <span [data-feather]="'arrow-right'"></span>
                                    </button>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                                    let-offset="offset">
                                    <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                                        Total de registros {{rowCount}}
                                    </div>
                                    <div style="padding: 5px 10px" *ngIf="rowCount == 0 && !isLoadingPage">
                                        No hay registros ...
                                    </div>
                                    <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                                        Cargando información...
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage"
                                        [size]="pageSize" (change)="onChange($event)"
                                        [hidden]="!((rowCount / pageSize) > 1)">
                                    </datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-center" *ngIf="cargandoEvaluadores">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>          
                <div class="col-sm-12 col-md-6">
                    <ngb-alert [type]="'warning'" [dismissible]="false"
                        *ngIf="evaluadores?.length == 0 && !cargandoEvaluadores">
                        <h4 class="alert-heading">No hay evaluadores</h4>
                        <div class="alert-body">
                            El Colaborador no tiene evaluadores
                        </div>
                    </ngb-alert>
                    <button *ngIf="evaluadores?.length == 0 && !cargandoEvaluadores" type="button"
                        (click)="agregarEvaluadorJefe()" class="btn btn-primary mx-1" rippleEffect>
                        <span [data-feather]="'plus-square'" [class]="'mr-25'"></span> Añadir
                    </button>
                    <div class="card card-employee-task" *ngIf="evaluadores?.length > 0">
                        <div class="card-header">
                            <h4 class="card-title">Evaluadores de {{nombreColaborador}}</h4>
                        </div>
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th>NOMBRE</th>
                                    <th>RECHAZAR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let evaluador of evaluadores">
                                    <td>
                                        <div class="media">
                                            <div class="avatar mr-75">
                                                <img src="{{urlImage}}{{evaluador.ID_evaluador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                class="" width="42" height="42" data-placement="left"/>
                                            </div>
                                            <div class="media-body my-auto">
                                                <h6 class="mb-0">{{evaluador.nombreEvaluador}}</h6>
                                                <span class="text-secondary">
                                                    {{evaluador.relacionEvaluador === 'CLIENTE' ? 'OBSERVADOR' : (evaluador.relacionEvaluador | uppercase) }}
                                                </span>    
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="enableRechazar">
                                        <button type="button" (click)="desaprobarEvaluador(evaluador)"
                                        class="btn btn-icon btn-icon rounded-circle btn-danger"
                                        title="Rechazar a {{evaluador.nombreEvaluador}}">
                                        <span [data-feather]="'x-circle'"></span>
                                        </button>
                                    </td>
                                    <td class="d-flex justify-content-center" *ngIf="!enableRechazar">
                                        <button type="button" (click)="errorRechazar()" class="btn btn-icon btn-icon rounded-circle btn-danger" disabled>
                                        <span [data-feather]="'x-circle'"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <div class="card-footer">
                            <button type="button" (click)="agregarEvaluadorJefe()" class="btn btn-primary mx-1"  rippleEffect *ngIf="enableRechazar">
                                <span [data-feather]="'plus-square'" [class]="'mr-25'"></span> Añadir
                            </button>
                            <button type="button" class="btn btn-primary mx-1" (click)="errorAgregar()" disabled rippleEffect *ngIf="!enableRechazar">
                                <span [data-feather]="'plus-square'" [class]="'mr-25'"></span> Añadir
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Desaprobar evaluador-->
<div *ngIf="showMotivo">
    <div class="row justify-content-center">
        <div class="col-sm-12 col-md-7">
            <h1 class="mt-2 mb-4 text-center">Motivo</h1>
            <div class="card">
                <div class="card-body">
                    <div class="media mb-2">
                        <div class="avatar mr-75">
                            <img src="{{urlImage}}{{evaluadorDesaprobado.ID_evaluador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                class="" width="42" height="42" data-placement="left"/>
                        </div>
                        <div class="media-body my-auto">
                            <h6 class="mb-0">{{evaluadorDesaprobado.nombreEvaluador}}</h6>
                            <p class="mb-0 text-secondary">{{evaluadorDesaprobado.relacionEvaluador | uppercase}}</p>
                        </div>
                    </div>
                    <form [formGroup]="formDesaprobar">
                        <label for="textAreaMotivo">Motivo</label>
                        <fieldset class="form-group">
                            <textarea class="motivo form-control" formControlName="motivo" id="textAreaMotivo" rows="3"
                                placeholder="En este campo explica el motivo por el cual desaprobaste a este evaluador"
                                [ngClass]="{'is-invalid': ((formDesaprobar.get('motivo').touched || formDesaprobar.get('motivo').dirty) && formDesaprobar.get('motivo').errors)}"></textarea>
                            <div *ngIf="formDesaprobar.get('motivo').hasError('required') && (formDesaprobar.get('motivo').dirty || formDesaprobar.get('motivo').touched)"
                                class="invalid-feedback">
                                El motivo es obligatorio
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            <button *ngIf="!rechazando" class="btn btn-warning" [disabled]="!formDesaprobar.valid"
                (click)="desabilitarEvaluador()">Guardar</button>
            <button class="btn btn-warning" type="button" disabled rippleEffect *ngIf="rechazando">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-25 align-middle">Guardando...</span>
            </button>
            <button class="btn btn-danger mx-1" (click)="cancelarDesaprobar()">Cancelar</button>
        </div>
    </div>
</div>
<!--Fin Desaprobar evaluador-->

<!--Agregar Evaluador a un subalterno-->
<div *ngIf="showAgregar">
    <div class="card">
        <div class="modal-content p-0">
            <div class="card-body">
                <form class="form form-horizontal" [formGroup]="formBuscarAgregar">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="nombre"
                                    placeholder="Nombre del evaluador" />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary" (click)="obtenerEvaluadores()">
                                        <i data-feather="search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div *ngIf="cargandoEvaluadores" class="mt-2 d-flex align-items-center justify-content-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!cargandoEvaluadores && notFoundEvaluadores && posiblesEvaluadores.length == 0" class="my-1">
                                No se encontraron resultados. 
                            </div>
                            <div class="card card-employee-task m-0">
                                <div class="card-header" *ngIf="!cargandoEvaluadores && posiblesEvaluadores?.length > 0">
                                    <h4 class="card-title">Colaboradores</h4>
                                </div>
                                <table class="table table-borderless" *ngIf="!cargandoEvaluadores && posiblesEvaluadores?.length > 0">
                                    <thead>
                                        <tr>
                                            <th>NOMBRE</th>
                                            <th>AGREGAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let evaluador of posiblesEvaluadores">
                                            <td class="pr-0 pl-0">
                                                <div class="media">
                                                    <div class="avatar mr-75">
                                                        <img src="{{urlImage}}{{evaluador.ID_colaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                class="" width="42" height="42" data-placement="left"/>
                                                    </div>
                                                    <div class="media-body my-auto">
                                                        <h6 class="mb-0">{{evaluador.nombre}}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <!--<td *ngIf="isAdmin != 'Administradores'">
                                                <button class="btn btn-primary btn-sm" (click)="agregarEvaluador(evaluador, 'PAR')">PAR</button>
                                                <button class="btn btn-primary btn-sm mx-1" (click)="agregarEvaluador(evaluador, 'CLIENTE')">CLIENTE</button>
                                            </td>
                                            <td *ngIf="isAdmin === 'Administradores'">
                                                <button class="btn btn-primary btn-sm ancho mr-1 mb-1" (click)="agregarEvaluador(evaluador, 'PAR')">PAR</button>
                                                <button class="btn btn-primary btn-sm ancho mb-1" (click)="agregarEvaluador(evaluador, 'CLIENTE')">CLIENTE</button>
                                                <button class="btn btn-primary btn-sm ancho mr-1" (click)="agregarEvaluador(evaluador, 'LÍDER')" *ngIf="isAdmin === 'Administradores'">LÍDER</button>
                                                <button class="btn btn-primary btn-sm ancho" (click)="agregarEvaluador(evaluador, 'COLABORADOR')" *ngIf="isAdmin === 'Administradores'" title="COLABORADOR">COLAB.</button>
                                            </td> -->
                                            <!-- Mostrar spinner mientras se valida -->
                                            <div *ngIf="cargandoPorEvaluador[evaluador.ID_colaborador]" class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Validando...</span>
                                            </div>
                                            <!-- Mostrar botones si no se está validando -->
                                            <td *ngIf="isAdmin != 'Administradores'">
                                                <!-- Botón para usuarios no administradores -->
                                                <button *ngIf="!cargandoPorEvaluador[evaluador.ID_colaborador]" 
                                                        class="btn btn-primary btn-sm" 
                                                        (click)="agregarEvaluador(evaluador, 'CLIENTE')"
                                                        [disabled]="evaluadoresDeshabilitados[evaluador.ID_colaborador]"
                                                        [ngClass]="{'btn-disabled': evaluadoresDeshabilitados[evaluador.ID_colaborador]}">
                                                    OBSERVADOR
                                                </button>
                                              </td>
                                              
                                              <td *ngIf="isAdmin === 'Administradores'">
                                                <!-- Botones para usuarios administradores -->
                                                <button 
                                                  class="btn btn-primary btn-sm ancho mr-1" 
                                                  (click)="agregarEvaluador(evaluador, 'CLIENTE')" 
                                                  [disabled]="evaluadoresDeshabilitados[evaluador.ID_colaborador]" 
                                                  [ngClass]="{'btn-disabled': evaluadoresDeshabilitados[evaluador.ID_colaborador]}"
                                                >
                                                  OBSERV.
                                                </button>
                                                
                                                <button 
                                                  class="btn btn-primary btn-sm ancho mr-1" 
                                                  (click)="agregarEvaluador(evaluador, 'LÍDER')" 
                                                  *ngIf="isAdmin === 'Administradores'" 
                                                  [disabled]="evaluadoresDeshabilitados[evaluador.ID_colaborador]" 
                                                  [ngClass]="{'btn-disabled': evaluadoresDeshabilitados[evaluador.ID_colaborador]}"
                                                >
                                                  LÍDER
                                                </button>
                                                
                                                <button 
                                                  class="btn btn-primary btn-sm ancho" 
                                                  (click)="agregarEvaluador(evaluador, 'COLABORADOR')" 
                                                  *ngIf="isAdmin === 'Administradores'" 
                                                  title="COLABORADOR" 
                                                  [disabled]="evaluadoresDeshabilitados[evaluador.ID_colaborador]" 
                                                  [ngClass]="{'btn-disabled': evaluadoresDeshabilitados[evaluador.ID_colaborador]}"
                                                >
                                                  COLAB.
                                                </button>
                                              </td>                                                                             
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="card card-employee-task">
                                <div class="card-body">
                                    <div *ngIf="!cargandoEvaluadores">
                                        <div *ngFor="let evaluador of posiblesEvaluadores"
                                            class="employee-task d-flex justify-content-between align-items-center">
                                            <div class="media" style="cursor: pointer;"
                                                [title]="evaluador.cargo_colaborador">
                                                <div class="avatar mr-75">
                                                    <img src="assets/images/avatars/evaluador.png" class="rounded"
                                                        width="42" height="42" data-placement="left" />
                                                </div>
                                                <div class="media-body my-auto">
                                                    <h6 class="mb-0">{{evaluador.nombre_colaborador}}</h6>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <button class="btn btn-primary btn-sm"
                                                    (click)="agregarEvaluador(evaluador, 'PAR')">PAR</button>
                                                <button class="btn btn-primary btn-sm mx-1"
                                                    (click)="agregarEvaluador(evaluador, 'CLIENTE')">CLIENTE</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex justify-content-center align-items-center" *ngIf="agregandoEvaluador">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6" *ngIf="!agregandoEvaluador && evaluadoresAgregar.length > 0">
                            <div class="card card-employee-task">
                                <div class="card-header pr-0 pl-0">
                                    <h4 class="card-title">Evaluadores</h4>
                                </div>
                                <table class="table table-borderless" *ngIf="posiblesEvaluadores.length > 0">
                                    <thead>
                                        <tr>
                                            <th>NOMBRE</th>
                                            <th>ELIMINAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let evaluador of evaluadoresAgregar; let i = index">
                                            <td class="pr-0 pl-0">
                                                <div class="media">
                                                    <div class="avatar mr-75">
                                                        <img src="{{urlImage}}{{evaluador.ID_colaborador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                                class="" width="42" height="42" data-placement="left"/>
                                                    </div>
                                                    <div class="media-body my-auto">
                                                        <h6 class="mb-0">{{evaluador.nombre}}</h6>
                                                        <span class="text-secondary">{{ evaluador.tipo === 'CLIENTE' ? 'OBSERVADOR' : (evaluador.tipo | uppercase) }}</span>
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="d-flex justify-content-center">
                                                <button type="button" (click)="devolverEvaluador(evaluador, i)"
                                                class="btn btn-icon btn-icon rounded-circle btn-danger"
                                                title="Eliminar a {{evaluador.nombre_colaborador}}">
                                                <span [data-feather]="'x-circle'"></span>
                                            </button>
                                                <!-- <button type="button" (click)="devolverEvaluador(evaluador, i)"
                                                class="btn btn-icon rounded-circle btn-outline-primary"
                                                title="Devolver">
                                                <span [data-feather]="'arrow-left'"></span>
                                            </button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-center"
                            *ngIf="!agregandoEvaluador && evaluadoresAgregar?.length == 0 && posiblesEvaluadores?.length > 0 && !cargandoEvaluadores">
                            <ngb-alert [type]="'warning'" [dismissible]="false">
                                <h4 class="alert-heading">No hay evaluadores</h4>
                                <div class="alert-body">
                                    Debe buscar y seleccionar los evaluadores que desee
                                </div>
                            </ngb-alert>
                        </div>
                    </div>
                    <div class="row my-1">
                        <div class="col-sm-6">
                            <button class="btn btn-primary" (click)="goToEvaluadores()">Volver</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!--Fin Agregar Evaluador a un subalterno-->