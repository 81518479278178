import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  alertNotification(tipo:SweetAlertIcon, mensaje:string, time:number){
    Swal.fire({
      position: 'center',
      icon: tipo,
      title: mensaje,
      showConfirmButton: false,
      timer: time
    })
  }

  alertNotificationYN(tipo: SweetAlertIcon, mensaje: string, time: number, showConfirmation: boolean = false): Promise<boolean | null> {
    if (showConfirmation) {
      return Swal.fire({
        position: 'center',
        icon: tipo,
        title: mensaje,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        timer: time > 0 ? time : undefined,
      }).then((result) => {
        if (result.isConfirmed) {
          return true; // Usuario seleccionó "Sí"
        } else if (result.isDismissed) {
          return false; // Usuario seleccionó "No"
        }
        return null; // Ninguna acción tomada
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: tipo,
        title: mensaje,
        showConfirmButton: false,
        timer: time,
      });
      return Promise.resolve(null); // Comportamiento original
    }
  }
  
}
