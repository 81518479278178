import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthenticationService } from 'app/auth/service';
import { Colaborador } from 'app/main/models/colaborador';
import { Evaluador } from 'app/main/models/evaluador';
import { RequestAddEvaluador } from 'app/main/models/requestAddEvaluador';
import { RequestDesaprobar } from 'app/main/models/requestDesaprobar';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { ConfigGeneralServices } from '../../configuracion/configgeneral.service';
import { RegistroEvaluadoresService } from '../../registro-evaluadores/registro-evaluadores.service';
import { RevisarPostulacionesService } from './revisar-postulaciones.service';
import { VisualizarEvaluadosServices } from 'app/main/dashboard/etapa2/evaluacion-desempeno/visualizar-evaluados/visualizar-evaluados.service';

@Component({
  selector: 'app-revisar-postulaciones',
  templateUrl: './revisar-postulaciones.component.html',
  styleUrls: ['./revisar-postulaciones.component.scss']
})
export class RevisarPostulacionesComponent implements OnInit {
  urlImage = environment.urlImages;
  public ColumnMode = ColumnMode;
  contentHeader: object;
  formBuscar:FormGroup;
  formDesaprobar:FormGroup;
  formBuscarAgregar:FormGroup;
  rows:Colaborador[] = [];
  evaluadores:Evaluador[];
  evaluadoresAgregar:Colaborador[];
  nombreColaborador:string;
  cargandoSubalternos = false;
  cargandoEvaluadores = false;
  showMotivo = false;
  showAgregar = false;
  cantidadMaximaEvaluados = 15; 
  evaluadorDesaprobado:Evaluador;
  posiblesEvaluadores:Colaborador[];
  notFoundEvaluadores = false;
  isLoadingPage = false;
  limitOptions = [{ key: '15', value: 15 }, { key: '25', value: 25 }, { key: '50', value: 50 }, { key: '75', value: 75 }];
  selectedOption: number = 15;
  colaborador:Colaborador;
  searchValue = '';
  tempData = [];
  rechazando = false;
  statusAprobado = 'Aprobado';
  statusRechazado = 'Rechazado';
  attributes360:any;
  agregandoEvaluador = false;
  enableRechazar = true;
  cantidadEvaluados = 0; 
  cantidadEvaluadosTotales = 0; 
  disableButtons: boolean = false;
  evaluadoresDeshabilitados: { [id: number]: boolean } = {};
  isAdmin = this._authService.getPayload()['cognito:groups'][0];
  public payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
  validacionesPendientes: number = 0;
  // Rastrea rastrear el estado de carga
  cargandoPorEvaluador: { [id: number]: boolean } = {};


  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private visualizarEvaluadosService:VisualizarEvaluadosServices, private formBuilder:FormBuilder, private revisarPostulacionesService:RevisarPostulacionesService,
              private alert:SweetAlertService, private registroEvaluadoresService: RegistroEvaluadoresService,
              private _configuracionService:ConfigGeneralServices, private _authService:AuthenticationService, private router:Router) { }

  ngOnInit(): void {
    this.attributes360 = JSON.parse(localStorage.getItem('attributes360'));
    this.getContentHeader();
    this._configuracionService.getConfiguration().subscribe(data => {
      if(data != null){
        if(data['etapaActual'] === "Etapa 2"){
          if(this.payload == "Administradores"){
            this.enableRechazar = true;
          }else{
            this.enableRechazar = false;
          }
        }
      }
    },err  => {
      console.log(err);
    })
    this.formBuscar = this.formBuilder.group({
      nombre: ['', [Validators.nullValidator]]
    })

    this.formDesaprobar = this.formBuilder.group({
      motivo: ['', [Validators.required]]
    })

    this.formBuscarAgregar = this.formBuilder.group({
      nombre: ['', [Validators.nullValidator]]
    })
    this.obtenerSubalternos();
  }

  get mostrandoCargador(): boolean {
    return this.cargandoEvaluadores || this.validacionesPendientes > 0;
  }  

  getContentHeader(){
    this.evaluadoresAgregar = [];
    this.contentHeader = {
      headerTitle: 'Postulaciones de los colaboradores',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Postulaciones',
            isLink: false,
            link: '/'
          },
        ]
      }
    }
  }
     
  filterAll() {
    const val = this.searchValue.toLowerCase();
    let filterAllData = this.tempData;
    
    if (val !== '') {
      filterAllData = filterAllData.filter(function (d) {
      return (d.nombre && d.nombre.toLowerCase().indexOf(val) !== -1) || !val;
      });
    }

    this.rows = filterAllData;
    return filterAllData;
  }

  obtenerSubalternos(){
    if(this.attributes360['preferred_username']){
      this.cargandoSubalternos = true;
      this.isLoadingPage = true;
      this.revisarPostulacionesService.obtenerSubalternosPorIdJefe(this.attributes360['preferred_username'], 10).subscribe(data => {
        if(data['codRespuesta'] === 0){
          this.rows = data['datos'];
          this.tempData = this.rows;
          this.cargandoSubalternos = false;
        }
        this.isLoadingPage = false;
      },err => {
        console.log(err);
        this.cargandoSubalternos = false;
        this.isLoadingPage = false;
      })
    }
  }

  reCargarSubalternos(){
    if(this.formBuscar.controls['nombre'].value === ''){
      this.obtenerSubalternos();
    }
  }

  verEvaluadores(colaborador:Colaborador){
    this.cargandoEvaluadores = true;
    this.evaluadores = [];
    this.colaborador = colaborador;
    this.revisarPostulacionesService.getEvaluadoresPorIdUsuario(colaborador.ID_colaborador, 10).subscribe(data => {
      if(data['datos'].length > 0){
        this.evaluadores = data['datos'];
        this.nombreColaborador = colaborador.nombre;
      }
      console.log("Datos: ", data['datos']);
      localStorage.setItem("evaluadores_actuales", JSON.stringify(data['datos']));
      this.cargandoEvaluadores = false;
    },err => {
      console.log(err);
      this.cargandoEvaluadores = false;
    });
  }

  desaprobarEvaluador(evaluador:Evaluador){
    evaluador.aprobacion = this.statusRechazado;
    if(evaluador.aprobacion === this.statusRechazado){
      this.showMotivo = true;
      this.evaluadorDesaprobado = evaluador;
    }
  }

  agregarEvaluadorJefe(){
    this.showAgregar = true;
  }

  async obtenerEvaluadores() {
    this.cargandoEvaluadores = true;
    this.evaluadoresDeshabilitados = {}; // Limpiar estado previo
    //agregar un campo más en obtenerColaboradoresPorNombre id y nombre 
    if (this.formBuscarAgregar.controls['nombre'].value !== '') {
      this.registroEvaluadoresService.obtenerColaboradorerPorNombre(this.formBuscarAgregar.controls['nombre'].value).subscribe(data => {
        if (data != null && data['datos'].length > 0 && data['codRespuesta'] === 0) {
          this.posiblesEvaluadores = data['datos'] as Colaborador[];
  
          // Procesar evaluadores de forma incremental
          this.posiblesEvaluadores.forEach(evaluador => {
            //console.log("Posibles Evaluadores: " , evaluador.ID_colaborador)
            this.validarEvaluador(evaluador.ID_colaborador);
          });
        }
        this.cargandoEvaluadores = false;
      }, err => {
        console.error(err);
        this.posiblesEvaluadores = [];
        this.notFoundEvaluadores = true;
        this.cargandoEvaluadores = false;
      });
    } else {
      this.posiblesEvaluadores = [];
      this.cargandoEvaluadores = false;
      this.notFoundEvaluadores = false;
    }
  }

  // Validar evaluador
  validarEvaluador(evaluador: number) {
    // Marca que el evaluador está en proceso de validación
    this.cargandoPorEvaluador[evaluador] = true;
  
    this.visualizarEvaluadosService.getEvaluadosPorIdUsuario(evaluador).subscribe(data => {
      //const cantidadEvaluados = Array.isArray(data) ? data.length : 0;

      this.cantidadEvaluados = 0;

      //console.log("DataEvaluado: ", data)

      // Verifica que data sea un array y recorre cada elemento para validar cuantos evaluados de tipo Observador tiene cada
      // evaluador
      
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((evaluado: any, index: number) => {
          const relacionEvaluador = evaluado?.relacionEvaluador || 'Sin rol definido';
          if(relacionEvaluador == 'CLIENTE'){
            this.cantidadEvaluados++; 
            console.log("Nombre: ", evaluado?.nombreEvaluador)
            //console.log(`Rol Evaluado (Elemento ${index}): `, relacionEvaluador);
            console.log("Cantidad de Observadores: ", this.cantidadEvaluados)
            console.log("Cantidad Máxima de evaluadores: ", this.cantidadMaximaEvaluados);
          }
        });
      }
  
      // Actualizar el estado del botón deshabilitado en caso de que el evaluador tenga más de x cantidad de 
      // evaluados de tipo Observador
      this.evaluadoresDeshabilitados[evaluador] = this.isAdmin != "Administradores" 
      && this.cantidadEvaluados >= this.cantidadMaximaEvaluados;

    }, err => {
      console.error(err);
      // En caso de error, no se deshabilita el botón
      this.evaluadoresDeshabilitados[evaluador] = false;
    }, () => {
      // Marcar que la validación de este evaluador ha terminado
      this.cargandoPorEvaluador[evaluador] = false;
    });
  }
  

  agregarEvaluador(evaluador: Colaborador, tipo: string) {
    this.visualizarEvaluadosService.getEvaluadosPorIdUsuario(evaluador.ID_colaborador).subscribe((data: any[]) => {
      if (data != null) {
        // Si el código llega hasta aquí, es porque el evaluador puede ser agregado
        this.agregandoEvaluador = true;
        evaluador.tipo = tipo;
  
        let requestAddEvaluador = new RequestAddEvaluador();
        requestAddEvaluador.ID_evaluado = this.colaborador.ID_colaborador;
        requestAddEvaluador.ID_evaluador = evaluador.ID_colaborador;
        requestAddEvaluador.nombreEvaluador = evaluador.nombre;
        requestAddEvaluador.relacionEvaluador = evaluador.tipo;
        requestAddEvaluador.aprobacion = this.statusAprobado;
  
        console.log("Evaluador: ", requestAddEvaluador.ID_evaluador);
  
        const evaluadoresActuales = localStorage.getItem("evaluadores_actuales");
  
        const comprobarEvaluadores = this.comprobarEvaluadores(evaluadoresActuales, requestAddEvaluador);
  
        if (comprobarEvaluadores) {
          return;
        }
  
        // Llamada al servicio para asociar evaluador
        this.registroEvaluadoresService.asociarEvaluador(requestAddEvaluador).subscribe(data => {
          const codRespuesta = data['codRespuesta'];
  
          switch (codRespuesta) {
            case 0:
              // Verificar si el evaluador ya está en la lista
              const existingIndex = this.evaluadoresAgregar.findIndex(
                (existingEvaluador) => existingEvaluador.ID_colaborador === evaluador.ID_colaborador
              );
  
              if (existingIndex !== -1) {
                // Si ya existe, reemplazarlo
                this.evaluadoresAgregar[existingIndex] = evaluador;
              } else {
                // Si no existe, agregarlo
                this.evaluadoresAgregar.push(evaluador);
              }
  
              this.alert.alertNotification('success', 'Evaluador agregado o actualizado!', 1500);
              break;
  
            case 8:
              this.alert.alertNotification('info', `${evaluador.nombre} ya es evaluador de ${this.colaborador.nombre}`, 3000);
              break;
  
            case 12:
              this.alert.alertNotification('info', `${evaluador.nombre} se encuentra en estado Rechazado`, 3000);
              break;
  
            default:
              this.alert.alertNotification('error', 'Respuesta inesperada del servidor', 1500);
              break;
          }
          this.agregandoEvaluador = false;
        }, err => {
          console.error("Error del servicio:", err);
  
          // Verificar si el backend devuelve un mensaje específico
          const errorMessage = err?.error?.mensaje || "Error desconocido al agregar el evaluador";
  
          // Mostrar una alerta con el mensaje del error
          this.alert.alertNotification('error', errorMessage, 1500);
  
          // Cambiar el estado para indicar que se detuvo la operación
          this.agregandoEvaluador = false;
        });
      }
    }, err => {
      console.log("error: ", err);
      this.isLoadingPage = false;
    });
  }
  

  comprobarEvaluadores(evaluadoresActuales,requestAddEvaluador){
    if (evaluadoresActuales) {
      const parsedEvaluadores = JSON.parse(evaluadoresActuales); // Convertir de cadena a objeto
      console.log("EvaluadoresActuales (Objeto): ", parsedEvaluadores);

      // Iterar sobre cada evaluador con un `for`
      console.log("Lista de Evaluadores:");
      for (let i = 0; i < parsedEvaluadores.length; i++) {
        console.log(`Evaluador ${i + 1}:`);
        const idEvaluador = parsedEvaluadores[i].ID_evaluador
        if(idEvaluador == requestAddEvaluador.ID_evaluador){
          if (this.payload != "Administradores") {
            const errorMessage = "Evaluador ya registrado para este evaluado";
            this.alert.alertNotification('error', errorMessage, 1500);
            this.agregandoEvaluador = false;
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      console.log("No se encontraron evaluadores actuales en el almacenamiento local.");
    }
  }
  

  desabilitarEvaluador(){
    Swal.fire({
      title: '¿Esta seguro de rechazar al evaluador?',
      text: "No podra revertirlo!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0033a1',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Rechazar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.attributes360['preferred_username']){
          this.rechazando = true;
          let request = new RequestDesaprobar();
          request.ID_evaluado = this.evaluadorDesaprobado.ID_evaluado;
          request.ID_evaluador = this.evaluadorDesaprobado.ID_evaluador;
          request.motivoInactivacion = this.formDesaprobar.controls['motivo'].value;
          request.ID_revisor = Number(this.attributes360['preferred_username']);
          this.revisarPostulacionesService.desaprobarEvaluadorSubalterno(request).subscribe(data => {
            if(data != null){
              this.rechazando = false;
              this.verEvaluadores(this.colaborador);
              this.formDesaprobar.reset();
              this.alert.alertNotification('success', 'Evaluador rechazado!', 1500);
              this.showMotivo = false;
            }
          },err => {
            this.rechazando = false;
            console.log(err);
            this.alert.alertNotification('error', 'Error al rechazar el evaluador', 3000);
          })
        }
      }
    })
  }

  goToEvaluadores(){
    if(this.evaluadoresAgregar.length > 0){
      this.verEvaluadores(this.colaborador);
      if(this.evaluadoresAgregar.length === 1){
        this.alert.alertNotification('success', 'Evaluador agregado con éxito!', 1500);
      }else{
        this.alert.alertNotification('success', 'Evaluadores agregados con éxito', 1500);
      }
    }
    this.formBuscarAgregar.controls['nombre'].setValue('');
    this.posiblesEvaluadores = [];
    this.evaluadoresAgregar = [];
    this.showAgregar = false;
  }

  devolverEvaluador(evaluador:Colaborador, i:number){
    Swal.fire({
      title: 'Eliminar',
      text: "¿Desea eliminar a " + this.evaluadoresAgregar[i]['nombre'] + " de su lista de Evaluadores?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0033a1',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.registroEvaluadoresService.eliminarEvaluador(this.colaborador.ID_colaborador, evaluador.ID_colaborador).subscribe(data => {
          if(data != null && data['codRespuesta'] === 0){
            this.evaluadoresAgregar.splice(i, 1);
            this.alert.alertNotification('success', 'Evaluador eliminado!', 1500);
          }
        },err => {
          console.log(err);
        })
      }
    })
  }

  cancelarDesaprobar(){
    this.formDesaprobar.reset();
    this.showMotivo = false;
  }

  errorRechazar(){
    this.alert.alertNotification('info', 'No se pueden rechazar evaluadores en la etapa 2', 3000);
  }

  errorAgregar(){
    this.alert.alertNotification('info', 'No se pueden agregar evaluadores en la etapa 2', 3000);
  }

  changePageSize(event) {
    this.table.pageSize = this.selectedOption;
    this.table.limit = this.table.pageSize;
    this.table.offset = 0;
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  onChange(pageInfo) {
    this.table.offset = pageInfo.page - 1;
  }
}
