import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoreoEtapa2Service {

  private endPointMonitoreoEtapa2:string = `${environment.apiMonitoreo2}/etapa2`;

  constructor(private _http:HttpClient) { }

  /**
   * Metodo que obtiene los colaborados para el monitoreo de la etapa 2
   * @param limite
   * @returns 
   */
  obtenerColaboradores(limite:number){
    let params = new HttpParams();
    params = params.append('limite', limite);
    return this._http.get(this.endPointMonitoreoEtapa2, {params:params}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }


   /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}