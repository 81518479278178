import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestAddEvaluador } from 'app/main/models/requestAddEvaluador';
import { environment } from 'environments/environment';
import { Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';

@Injectable({
  providedIn: 'root'
})
export class RegistroEvaluadoresService {

  endPointEvaluadores:string = `${environment.apiGeneral}/evaluadores`;
  endPointPersonas:string = `${environment.apiGeneral}/personas`;
  private _refresh$ = new Subject<void>();

  constructor(private http:HttpClient, private alerta : SweetAlertService) { }

  get refresh$(){
    return this._refresh$;
  }

  getHeaders() {
    let accessToken = '';
    for (let a in localStorage) {
      if (a.endsWith('.accessToken'))
        accessToken = localStorage[a];
    }

    let headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ accessToken
    });
    return headers_object;
  }

  /**
   * Obtiene lista de evaluadores por idUsuario
   * @param idUsuario
   * @param limite 
   * @returns 
   */
  getEvaluadoresPorIdUsuario(idUsuario:number, limite:number){
    let params= new HttpParams();
    params = params.append('idUsuario', idUsuario);
    // params = params.append('limite', limite)
    return this.http.get(this.endPointEvaluadores, {params:params, headers:this.getHeaders()} ).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Metodo que obtiene los colaboradores dependiendo del nombre
   * @param nomUsuario 
   * @returns 
   */
  obtenerColaboradorerPorNombre(nomUsuario:string){
    let params = new HttpParams();
    params = params.append('nomUsuario', nomUsuario);
    return this.http.get(this.endPointPersonas, {params:params, headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Asocia a un colaborador a la lista de evaluadores de otro colaborador
   * @param requestAddEvaluador 
   * @returns 
   */
  asociarEvaluador(requestAddEvaluador:RequestAddEvaluador){
    return this.http.post(this.endPointEvaluadores, requestAddEvaluador, {headers:this.getHeaders()}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Elimina a un evaluador
   * @param idUsuario 
   * @param idEvaluador 
   * @returns 
   */
  eliminarEvaluador(idUsuario:number, idEvaluador:number){
    let payload = JSON.parse(localStorage.getItem('payload'))['cognito:groups'][0];
    if(payload === "Jefes" || payload === "Administradores"){
      let params = new HttpParams();
      params = params.append('idUsuario', idUsuario);
      params = params.append('idEvaluador', idEvaluador);
      return this.http.delete(this.endPointEvaluadores, {params:params, headers:this.getHeaders()}).pipe(
        tap(() => {
          this._refresh$.next();
        }),
        catchError(this.handleError)
      )

    } else {
      this.alerta.alertNotification('error', 'Solo usuario de tipo jefe o administrador pueden eliminar evaluadores', 1500);
      
    }
    
  }

  obtenerImagenColaborador(){
    return this.http.get('https://imagenes-funcionarios.s3.amazonaws.com/1072649999.jpg').pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
  * Catch errors on call WS
  * @param error
  */
   private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}
