<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section class="invoice-list-wrapper">
            <div class="card">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <label class="d-flex align-items-center">Ver&nbsp;
                                    <select class="form-control mx-20" [(ngModel)]="selectedOption" (ngModelChange)="changePageSize($event)">
                                        <option *ngFor="let opt of limitOptions" [ngValue]="opt.value">{{opt.key}}
                                        </option>
                                    </select>
                                </label>
                                <!--<button class="btn btn-primary ml-1" (click)="goToAgregarEvaluador()" *ngIf="anadirActivo">Añadir+</button> 
                                <button class="btn btn-primary ml-1" disabled  *ngIf="!anadirActivo">Añadir+</button> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12"></div>
                    <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end align-items-center">
                        <div class="row pb-md-0 align-items-center w-100">
                            <div class="col-sm-12 col-md-6 pr-0">
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                                    <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control" placeholder="Búsqueda" (keyup)="filterAll()"/>
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-6 ml-md-0 my-sm-1 ml-sm-1 my-1 ml-1 mr-0 pr-0 pr-sm-1">
                                <fieldset class="form-group mb-0 mr-sm-0 mr-md-1 mr-0 pr-0">
                                    <ng-select
                                      [items]="selectStatus"
                                      [(ngModel)]="selectedStatus"
                                      bindLabel="name"
                                      placeholder="Seleccione estado"
                                      (change)="filterAll()"
                                    ></ng-select>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
                <ngx-datatable #table [rows]="rows" [rowHeight]="50" class="bootstrap core-bootstrap" [limit]="selectedOption"
                [columnMode]="ColumnMode.force" [headerHeight]="120" [footerHeight]="50" [scrollbarH]="true" [messages]="{emptyMessage: ''}" >
                    <ngx-datatable-column name="Nombre" prop="nombreEvaluador" [width]="400">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="d-flex">
                                <div class="avatar mr-75">
                                    <img src="{{urlImage}}{{row.ID_evaluador}}.jpg" onerror="this.onerror=null;this.src='assets/images/avatars/evaluador.png'"
                                        class="" width="42" height="42" data-placement="left"/>
                                </div>
                                <div class="columnNomEvaluador">
                                    {{row?.nombreEvaluador}}
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Relación" prop="relacionEvaluador" [width]="180" >
                        <ng-template let-row="row" ngx-datatable-cell-template style="background-color: white !important;">
                            
                                {{row?.relacionEvaluador === 'CLIENTE' ? 'OBSERVADOR' : (row?.relacionEvaluador | uppercase) }}
                         
                           <!-- {{row?.relacionEvaluador }} --> 
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Estado" prop="aprobacion" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="badge badge-glow" [ngClass]="{'badge-success': row.aprobacion == 'Aprobado', 'badge-danger': row.aprobacion == 'Rechazado'}">
                                {{row?.aprobacion }}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Cargo" prop="cargoEvaluador" [width]="400">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="columnCargo">
                                {{row?.cargoEvaluador}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Área" prop="areaEvaluador" [width]="400">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="columnArea">
                                {{row?.areaEvaluador}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Acción" [sortable]="false"> 
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center col-actions" *ngIf="enableEliminar && row.eliminar === 0">
                                <a class="mr-1" href="javascript:void(0);" container="body" placement="top" (click)="eliminarEvaluador(row)" ngbTooltip="Eliminar a {{row.nombreEvaluador}}">
                                    <i size="18" data-feather="trash-2"></i>
                                </a>
                            </div>
                            <div class="d-flex align-items-center col-actions" *ngIf="!enableEliminar && row.eliminar === 0">
                                <a class="mr-1" href="javascript:void(0);" container="body" placement="top" (click)="errorEliminar()">
                                    <i size="18" data-feather="trash-2"></i>
                                </a>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                          let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
                          <div style="padding: 5px 10px" *ngIf="rowCount > 0 ">
                            Total de registros {{rowCount}}
                          </div>
                          <div style="padding: 5px 10px" *ngIf="rowCount == 0 && !isLoadingPage">
                            No hay evaluadores ...
                          </div>
                          <div style="padding: 5px 10px" *ngIf="rowCount == 0 && isLoadingPage">
                            Cargando información...
                          </div>
                          <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'" [count]="rowCount" [page]="curPage" [size]="pageSize"
                            (change)="onChange($event)" [hidden]="!((rowCount / pageSize) > 1)">
                          </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </section>
    </div>
</div>